<template>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
    <div class="card">
      <div class="card-header">Profile</div>
      <div class="card-body">
        <v-form ref="form" lazy-validation v-model="valid">
          <div class="row" v-if="profile">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Username</label>
              <v-text-field type="text" v-model="profile.username" outlined ></v-text-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Profile Image</label>
              <v-file-input class="mt-1" accept="image/*" v-model="profile_image" prepend-icon=" " @change="addProfileImageToS3" prepend-inner-icon="mdi-image" placeholder="Choose image" outlined></v-file-input>
              <!-- <v-progress-linear :value="profile_progress" v-if="profile_image && profile_progress != 100" color="light-blue" height="10" striped></v-progress-linear> -->
              <!-- <div v-if="profile.profile_pic" class="row">
                <div class="col-4 ml-1" style="border:1px solid #ddd" v-if="profile.profile_pic">
                  <img :src="profile.profile_pic" height="50" style="pointer-events:none;">
                </div>
              </div> -->
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Name<span class="red--text"><strong>* </strong></span></label>
              <v-text-field type="text" v-model="profile.name" :rules="[v => (!!v && !/[^a-zA-Z ]/g.test(v)) || 'Enter valid name']" outlined ></v-text-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Surname</label>
              <v-text-field type="text" v-model="profile.surname" outlined ></v-text-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Email<span class="red--text"><strong>* </strong></span></label>
              <v-text-field type="text" v-model="tutor.users.email" outlined readonly></v-text-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Country<span class="red--text"><strong>* </strong></span></label>
                <v-autocomplete v-model="profile.country" :items="countries" item-text="text" item-value="value" :rules="[v => !!v || 'Select country']" v-on:click="getRegions()" searchable outlined></v-autocomplete>
            </div>                  
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Region</label>
                <v-autocomplete v-model="profile.region" :items="regions" item-text="text" item-value="value" searchable outlined></v-autocomplete>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="row">
                <div class="col-lg-3 pt-0">
                  <label for="inputText3" class="col-form-label">Code</label>
                  <v-autocomplete v-model="profile.code" :items="codes" item-text="text" item-value="value"  @input="getCountry" outlined></v-autocomplete>
                </div>
                <div class="col-lg-9 pt-0">
                  <label for="inputText3" class="col-form-label">Contact Num</label>
                  <v-text-field type="text" v-model="tutor.users.mobile" outlined readonly></v-text-field>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pb-0">
              <label for="inputText3" class="col-form-label">Select Highest Educational Qualifcation</label>
                <v-autocomplete v-model="profile.highestEducationalQualifcation" :items="qualifications" item-text="text" item-value="value" searchable outlined></v-autocomplete>
            </div>   
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pb-0">
              <label for="inputText3" class="col-form-label">Accent</label>
              <v-autocomplete :items="accents" item-text="text" item-value="value" v-model="profile.accent" outlined ></v-autocomplete>
            </div> 
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pb-0">
              <label for="inputText3" class="col-form-label">Select Timezone</label>
              <v-autocomplete v-model="profile.timezone" :items="timezone" item-text="text" item-value="text" searchable outlined></v-autocomplete>
            </div>  
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pb-0"></div>     
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pt-0">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                  <label for="cars" class="col-form-label">Selected Subjects<span class="red--text"><strong>* </strong></span></label>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 pt-0 pb-0">
                  <div class="form-group">
                    <select multiple="" name="subjects" v-model="selectedSubject" id="sub" class="form-control">
                      <option :value="subject.value" v-for="(subject,index) in subjects" :key="index">{{subject.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 select-btn pt-0 pb-0">
                  <a href="" v-on:click.prevent="addSubject()" class="btn btn-default btn-xs mb-1">Add</a><br>
                  <a href="" v-on:click.prevent="removeSubject()" class="btn btn-danger btn-xs text-white">Remove</a>
                </div>
                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 pt-0 pb-0">
                  <div class="form-group">
                    <select multiple="" name="select_subjects" v-model="removedSubject" id="sel_sub" :rules="[v => !!(v &&v.length) || 'Select subject']"  class="form-control">
                      <option :value="sub.value" v-for="(sub,index) in selected_subjects" :key="index">{{sub.text}}</option>
                    </select>
                    <div class="error-feedback" v-if="!this.selected_subjects.length">Select subject</div>
                    <!-- <v-select v-model="removedSubject" :items="selected_subjects" item-text="text" item-value="value" :rules="[v => !!(v &&v.length) || 'Select subject']" outlined multiple></v-select> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pt-0">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                  <label for="cars" class="col-form-label">Selected  Grades </label>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 pt-0">
                  <div class="form-group">     
                    <select multiple="" name="garde" id="grade" v-model="selectedGrade" class="form-control">
                      <option :value="grade.value" v-for="(grade,index) in grades" :key="index">{{grade.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 select-btn">
                  <a href="" v-on:click.prevent="addGrade()" class="btn btn-default btn-xs mb-1">Add</a><br>
                  <a href="" v-on:click.prevent="removeGrade()" class="btn btn-danger btn-xs text-white">Remove</a>
                </div>
                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 pt-0">
                  <div class="form-group">      
                    <select multiple="" name="selected_grade" v-model="removedGrade" id="grade" class="form-control">
                      <option :value="grade.value" v-for="(grade,index) in selected_grades" :key="index">{{grade.text}}</option>
                    </select>
                    <!-- <div class="error-feedback" v-if="!this.selected_grades.length">Select grade</div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Tell me about yourself</label>
              <v-textarea v-model="profile.about" outlined ></v-textarea>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group">
                  <label for="inputText3" class="col-form-label">Certificate</label>
                  <a :href="tutor.university_certificate" class="btn btn-outline-violate btn-xs ml-1">Download</a>
                  <v-file-input class="mt-1" accept="image/*" v-model="profile.university_certificate" prepend-icon=" " prepend-inner-icon="mdi-image" placeholder="Choose file" outlined></v-file-input>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group">
                  <label for="inputText3" class="col-form-label">Demo Class Video</label>
                  <a :href="tutor.demo_class" download="" class="btn btn-outline-violate btn-xs ml-1">Download</a>
                  <v-file-input class="mt-1" accept="video/*" v-model="profile.demo_class" prepend-icon=" " prepend-inner-icon="mdi-movie" placeholder="Choose file" outlined></v-file-input>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <a href="" v-on:click.prevent="updateTutor()" class="btn btn-success btn-block text-white w-100">Save Changes</a>
            </div>
          </div>
        </v-form>
      </div>
    </div>
    <b-modal id="confirmation" ref="confirm-modal" title=" " hide-footer hide-header centered>
      <div class="container">
        <div class="icon-box text-center"><i class="far fa-times-circle"></i></div>		
        <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Are you sure?</h4>	
        <div class="modal-body text-center">
          <p>Do you really want to Update these tutor details?</p>
        </div>		
        <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal">Cancel</button>
            <button type="button" class="btn btn-danger" v-if="!loading" v-on:click="confirmUpdate">Save</button>
            <button type="button" class="btn btn-danger" v-if="loading">...</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import AWS from 'aws-sdk';
export default {
  name:'Tutor-Profile',
  props:["tutor"],
  data(){
    return {
      profile: {
        username:null,
        profile_pic:null,
        name:null,
        surname:null,
        about: null,
        country: null,
        region:null,
        highestEducationalQualifcation:null,
        accent:null,
        subjects:[],
        grades:[],
        university_certificate:null,
        demo_class:null,
        timezone:null,
      },
      countries:[],
      regions:[],
      codes:[],
      qualifications:[],
      accents:[],
      grades:[],
      selected_grades:[],
      subjects:[],
      selected_subjects:[],
      profile_progress:0,
      profile_image:null,
      // mobileRules:[
      //   v => !!v || 'Enter valid mobile',
      //   // v => /\D/g.test(v) == false || 'Enter 10 digit mobile number',
      //   // v => (v && v.length == 10) || 'Enter 10 digit mobile number'
      // ],
      // emailRules:[
      //   v => !!v || 'Enter valid email',
      //   v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Enter valid email',
      // ],
      selectedSubject:[],
      selectedGrade:[],
      removedSubject:[],
      removedGrade:[],
      valid:false,
      loading:false,
      js_timezone:[
        'Europe/Andorra',
        'Asia/Dubai',
        'Asia/Kabul',
        'Europe/Tirane',
        'Asia/Yerevan',
        'Antarctica/Casey',
        'Antarctica/Davis',
        'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
        'Antarctica/Mawson',
        'Antarctica/Palmer',
        'Antarctica/Rothera',
        'Antarctica/Syowa',
        'Antarctica/Troll',
        'Antarctica/Vostok',
        'America/Argentina/Buenos_Aires',
        'America/Argentina/Cordoba',
        'America/Argentina/Salta',
        'America/Argentina/Jujuy',
        'America/Argentina/Tucuman',
        'America/Argentina/Catamarca',
        'America/Argentina/La_Rioja',
        'America/Argentina/San_Juan',
        'America/Argentina/Mendoza',
        'America/Argentina/San_Luis',
        'America/Argentina/Rio_Gallegos',
        'America/Argentina/Ushuaia',
        'Pacific/Pago_Pago',
        'Europe/Vienna',
        'Australia/Lord_Howe',
        'Antarctica/Macquarie',
        'Australia/Hobart',
        'Australia/Currie',
        'Australia/Melbourne',
        'Australia/Sydney',
        'Australia/Broken_Hill',
        'Australia/Brisbane',
        'Australia/Lindeman',
        'Australia/Adelaide',
        'Australia/Darwin',
        'Australia/Perth',
        'Australia/Eucla',
        'Asia/Baku',
        'America/Barbados',
        'Asia/Dhaka',
        'Europe/Brussels',
        'Europe/Sofia',
        'Atlantic/Bermuda',
        'Asia/Brunei',
        'America/La_Paz',
        'America/Noronha',
        'America/Belem',
        'America/Fortaleza',
        'America/Recife',
        'America/Araguaina',
        'America/Maceio',
        'America/Bahia',
        'America/Sao_Paulo',
        'America/Campo_Grande',
        'America/Cuiaba',
        'America/Santarem',
        'America/Porto_Velho',
        'America/Boa_Vista',
        'America/Manaus',
        'America/Eirunepe',
        'America/Rio_Branco',
        'America/Nassau',
        'Asia/Thimphu',
        'Europe/Minsk',
        'America/Belize',
        'America/St_Johns',
        'America/Halifax',
        'America/Glace_Bay',
        'America/Moncton',
        'America/Goose_Bay',
        'America/Blanc-Sablon',
        'America/Toronto',
        'America/Nipigon',
        'America/Thunder_Bay',
        'America/Iqaluit',
        'America/Pangnirtung',
        'America/Atikokan',
        'America/Winnipeg',
        'America/Rainy_River',
        'America/Resolute',
        'America/Rankin_Inlet',
        'America/Regina',
        'America/Swift_Current',
        'America/Edmonton',
        'America/Cambridge_Bay',
        'America/Yellowknife',
        'America/Inuvik',
        'America/Creston',
        'America/Dawson_Creek',
        'America/Fort_Nelson',
        'America/Vancouver',
        'America/Whitehorse',
        'America/Dawson',
        'Indian/Cocos',
        'Europe/Zurich',
        'Africa/Abidjan',
        'Pacific/Rarotonga',
        'America/Santiago',
        'America/Punta_Arenas',
        'Pacific/Easter',
        'Asia/Shanghai',
        'Asia/Urumqi',
        'America/Bogota',
        'America/Costa_Rica',
        'America/Havana',
        'Atlantic/Cape_Verde',
        'America/Curacao',
        'Indian/Christmas',
        'Asia/Nicosia',
        'Asia/Famagusta',
        'Europe/Prague',
        'Europe/Berlin',
        'Europe/Copenhagen',
        'America/Santo_Domingo',
        'Africa/Algiers',
        'America/Guayaquil',
        'Pacific/Galapagos',
        'Europe/Tallinn',
        'Africa/Cairo',
        'Africa/El_Aaiun',
        'Europe/Madrid',
        'Africa/Ceuta',
        'Atlantic/Canary',
        'Europe/Helsinki',
        'Pacific/Fiji',
        'Atlantic/Stanley',
        'Pacific/Chuuk',
        'Pacific/Pohnpei',
        'Pacific/Kosrae',
        'Atlantic/Faroe',
        'Europe/Paris',
        'Europe/London',
        'Asia/Tbilisi',
        'America/Cayenne',
        'Africa/Accra',
        'Europe/Gibraltar',
        'America/Godthab',
        'America/Danmarkshavn',
        'America/Scoresbysund',
        'America/Thule',
        'Europe/Athens',
        'Atlantic/South_Georgia',
        'America/Guatemala',
        'Pacific/Guam',
        'Africa/Bissau',
        'America/Guyana',
        'Asia/Hong_Kong',
        'America/Tegucigalpa',
        'America/Port-au-Prince',
        'Europe/Budapest',
        'Asia/Jakarta',
        'Asia/Pontianak',
        'Asia/Makassar',
        'Asia/Jayapura',
        'Europe/Dublin',
        'Asia/Jerusalem',
        'Asia/Calcutta',
        'Indian/Chagos',
        'Asia/Baghdad',
        'Asia/Tehran',
        'Atlantic/Reykjavik',
        'Europe/Rome',
        'America/Jamaica',
        'Asia/Amman',
        'Asia/Tokyo',
        'Africa/Nairobi',
        'Asia/Bishkek',
        'Pacific/Tarawa',
        'Pacific/Enderbury',
        'Pacific/Kiritimati',
        'Asia/Pyongyang',
        'Asia/Seoul',
        'Asia/Almaty',
        'Asia/Qyzylorda',
        'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
        'Asia/Aqtobe',
        'Asia/Aqtau',
        'Asia/Atyrau',
        'Asia/Oral',
        'Asia/Beirut',
        'Asia/Colombo',
        'Africa/Monrovia',
        'Europe/Vilnius',
        'Europe/Luxembourg',
        'Europe/Riga',
        'Africa/Tripoli',
        'Africa/Casablanca',
        'Europe/Monaco',
        'Europe/Chisinau',
        'Pacific/Majuro',
        'Pacific/Kwajalein',
        'Asia/Yangon',
        'Asia/Ulaanbaatar',
        'Asia/Hovd',
        'Asia/Choibalsan',
        'Asia/Macau',
        'America/Martinique',
        'Europe/Malta',
        'Indian/Mauritius',
        'Indian/Maldives',
        'America/Mexico_City',
        'America/Cancun',
        'America/Merida',
        'America/Monterrey',
        'America/Matamoros',
        'America/Mazatlan',
        'America/Chihuahua',
        'America/Ojinaga',
        'America/Hermosillo',
        'America/Tijuana',
        'America/Bahia_Banderas',
        'Asia/Kuala_Lumpur',
        'Asia/Kuching',
        'Africa/Maputo',
        'Africa/Windhoek',
        'Pacific/Noumea',
        'Pacific/Norfolk',
        'Africa/Lagos',
        'America/Managua',
        'Europe/Amsterdam',
        'Europe/Oslo',
        'Asia/Kathmandu',
        'Pacific/Nauru',
        'Pacific/Niue',
        'Pacific/Auckland',
        'Pacific/Chatham',
        'America/Panama',
        'America/Lima',
        'Pacific/Tahiti',
        'Pacific/Marquesas',
        'Pacific/Gambier',
        'Pacific/Port_Moresby',
        'Pacific/Bougainville',
        'Asia/Manila',
        'Asia/Karachi',
        'Europe/Warsaw',
        'America/Miquelon',
        'Pacific/Pitcairn',
        'America/Puerto_Rico',
        'Asia/Gaza',
        'Asia/Hebron',
        'Europe/Lisbon',
        'Atlantic/Madeira',
        'Atlantic/Azores',
        'Pacific/Palau',
        'America/Asuncion',
        'Asia/Qatar',
        'Indian/Reunion',
        'Europe/Bucharest',
        'Europe/Belgrade',
        'Europe/Kaliningrad',
        'Europe/Moscow',
        'Europe/Simferopol',
        'Europe/Kirov',
        'Europe/Astrakhan',
        'Europe/Volgograd',
        'Europe/Saratov',
        'Europe/Ulyanovsk',
        'Europe/Samara',
        'Asia/Yekaterinburg',
        'Asia/Omsk',
        'Asia/Novosibirsk',
        'Asia/Barnaul',
        'Asia/Tomsk',
        'Asia/Novokuznetsk',
        'Asia/Krasnoyarsk',
        'Asia/Irkutsk',
        'Asia/Chita',
        'Asia/Yakutsk',
        'Asia/Khandyga',
        'Asia/Vladivostok',
        'Asia/Ust-Nera',
        'Asia/Magadan',
        'Asia/Sakhalin',
        'Asia/Srednekolymsk',
        'Asia/Kamchatka',
        'Asia/Anadyr',
        'Asia/Riyadh',
        'Pacific/Guadalcanal',
        'Indian/Mahe',
        'Africa/Khartoum',
        'Europe/Stockholm',
        'Asia/Singapore',
        'America/Paramaribo',
        'Africa/Juba',
        'Africa/Sao_Tome',
        'America/El_Salvador',
        'Asia/Damascus',
        'America/Grand_Turk',
        'Africa/Ndjamena',
        'Indian/Kerguelen',
        'Asia/Bangkok',
        'Asia/Dushanbe',
        'Pacific/Fakaofo',
        'Asia/Dili',
        'Asia/Ashgabat',
        'Africa/Tunis',
        'Pacific/Tongatapu',
        'Europe/Istanbul',
        'America/Port_of_Spain',
        'Pacific/Funafuti',
        'Asia/Taipei',
        'Europe/Kiev',
        'Europe/Uzhgorod',
        'Europe/Zaporozhye',
        'Pacific/Wake',
        'America/New_York',
        'America/Detroit',
        'America/Kentucky/Louisville',
        'America/Kentucky/Monticello',
        'America/Indiana/Indianapolis',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Vevay',
        'America/Chicago',
        'America/Indiana/Tell_City',
        'America/Indiana/Knox',
        'America/Menominee',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/North_Dakota/Beulah',
        'America/Denver',
        'America/Boise',
        'America/Phoenix',
        'America/Los_Angeles',
        'America/Anchorage',
        'America/Juneau',
        'America/Sitka',
        'America/Metlakatla',
        'America/Yakutat',
        'America/Nome',
        'America/Adak',
        'Pacific/Honolulu',
        'America/Montevideo',
        'Asia/Samarkand',
        'Asia/Tashkent',
        'America/Caracas',
        'Asia/Ho_Chi_Minh',
        'Pacific/Efate',
        'Pacific/Wallis',
        'Pacific/Apia',
        'Africa/Johannesburg'
      ],
      timezone:[],
    }
  },
  beforeMount(){
    this.getCountries();
    this.getQualifications();
    this.getAccents();
    this.js_timezone.forEach((time,index)=>{
      this.timezone.push({id:index+1,text:time})
    });
    if(this.tutor){
      this.profile.username = this.tutor?.username;
      this.profile.profile_pic = this.tutor?.profile_image;
      this.profile.name = this.tutor?.name;
      this.profile.surname = this.tutor?.surname;
      this.profile.about = this.tutor?.about;
      this.profile.code = parseInt(this.tutor?.users?.code);
      this.profile.mobile = this.tutor?.users.mobile;
      this.profile.email = this.tutor?.users.email;
      this.profile.country = (this.tutor?.country)?(this.tutor?.country.id):null;
      this.profile.region = (this.tutor?.region)?this.tutor?.region.id:null;
      this.profile.accent = this.tutor?.accent_id;
      this.profile.demoClass = this.tutor?.demo_class;
      this.profile.timezone = this.tutor?.timezone;
      this.profile.universityCertificate = this.tutor?.university_certificate;
      this.profile.highestEducationalQualifcation = this.tutor?.qualification_id;
      this.tutor.grades.forEach(grade => {
        if(grade.is_active == 'Y' && grade.grade){
          this.selected_grades.push({value:grade.grade_id, text:grade.grade.name});
          this.profile.subjects.push(grade.grade_id);
        }
      });
      this.tutor.subjects.forEach(sub => {
        if(sub.is_active == 'Y'&&sub.subject){
          this.selected_subjects.push({value:sub.subject_id, text:sub.subject?.subject_name});
          this.profile.grades.push(sub.subject_id);
        }
      });
    }
    this.listRegions(this.profile.country);
    this.getSubjects();
    this.getGrades();
  },
  methods:{
    hideModal(){
      this.$refs['confirm-modal'].hide();
    },
    getCountries(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/countries',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.countries.forEach(country => {
          this.countries.push({value:country.id,text:country.name,code:country.phonecode,short:country.sortname});
          this.codes.push({value:country.phonecode, text:country.sortname+ '-' + country.phonecode})
        })
      })
    },
    getCountry(){
      this.countries.forEach(country => {
        if(country.code == this.profile.code){
          this.profile.country = {value:country.value, text:country.text};
        }
      })
      this.profile.country_id = this.profile.country.value;
      this.listRegions(this.profile.country.value);
    },
    getRegions(){
      this.profile.country = this.tutor.country;
      this.countries.forEach(country => {
        if(country.value == this.tutor.country){
          this.tutor.code = {value:country.code, text:country.short+ '-' + country.code};
        }
      });
      this.listRegions(this.tutor.country);
    },
    listRegions(id){
      this.regions = [];
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/country/'+id,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.states.forEach(region => {
          this.regions.push({value:region.id,text:region.name});
        })
      })
    },
    getQualifications(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/qualification',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Qualification.forEach(qual => {
          if(qual.is_active == 'Y'){
            this.qualifications.push({value :qual.qualification_id,text:qual.name});
          }
        })
      })
    },
    getAccents(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/accents',{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        res.data.Accents.forEach(accent => {
          if(accent.is_active == 'Y'){
            this.accents.push({value:accent.accent_id,text:accent.name});
          }
        })
      })
    },
    getSubjects(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/subjects',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Subjects.forEach(subject => {
          let flag = 0;
          if(subject.is_active == 'Y' ){
            this.selected_subjects.forEach(sub => {
              if(subject.subject_id == sub.value){
                flag++;
              }
            });
          }
          if(flag == 0){
            this.subjects.push({value:subject.subject_id, text:subject.subject_name});
          }
        })
      })
    },
    getGrades(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/grades',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Grades.forEach(grade => {
          let flag = 0;
          if(grade.is_active == 'Y' ){
            this.selected_grades.forEach(g => {
              if(grade.grade_id == g.value){
                flag++;
              }
            });
          }
          if(flag == 0){
            this.grades.push({value:grade.grade_id,text:grade.name});
          }
        })
      })
    },
    addSubject(){
      if(this.selectedSubject.length){
        this.selectedSubject.forEach(id => {
          this.subjects.forEach((subject,index,object) =>{
            if(subject.value == id){
              this.selected_subjects.push({value:subject.value,text:subject.text});
              this.profile.subjects.push(subject.value)
              object.splice(index,1);
            }
          })
        });
        this.profile.subjects = [];
        this.selected_subjects.forEach(sub => {
          this.profile.subjects.push(sub.value);
        })
      }
    },
    removeSubject(){
      if(this.removedSubject.length){
        this.removedSubject.forEach(id => { 
          this.selected_subjects.forEach((subject,index,object)=>{
            if(subject.value == id){
              this.subjects.push({value:subject.value,text:subject.text});
              object.splice(index,1);
            }
          })
        });
        this.profile.subjects = [];
        this.selected_subjects.forEach(sub => {
          this.profile.subjects.push(sub.value);
        })
      }
    },
    addGrade(){
      if(this.selectedGrade.length){
        this.selectedGrade.forEach(id => {
          this.grades.forEach((grade,index,object) =>{
            if(grade.value == id){
              this.selected_grades.push({value:grade.value,text:grade.text});
              object.splice(index,1);
            }
          })
        });
        this.profile.grades = []
        this.selected_grades.forEach(grade => {
          this.profile.grades.push(grade.value);
        })
      }
    },
    removeGrade(){
      if(this.removedGrade.length){
        this.removedGrade.forEach(id => { 
          this.selected_grades.forEach((grade,index,object)=>{
            if(grade.value == id){
              this.grades.push({value:grade.value,text:grade.text});
              object.splice(index,1);
            }
          })
        });
        this.profile.grades = []
        this.selected_grades.forEach(grade => {
          this.profile.grades.push(grade.value);
        })      
      }
    },
    updateTutor(){
      this.valid = this.$refs.form.validate();
      if(this.valid && this.selected_subjects.length && this.selected_grades){
        this.$refs['confirm-modal'].show();
      }
    },
    confirmUpdate(e){
      e.preventDefault();
      this.loading = true;
      if((this.profile.demo_class && (this.profile.demo_class != this.tutor.demo_class)) || (this.profile.university_certificate && (this.profile.university_certificate != this.tutor.university_certificate))){
        var access_key = process.env.VUE_APP_S3_ACCESS_KEY;
        var secret_key = process.env.VUE_APP_S3_SECRET_KEY;
        let keys = { accessKeyId: access_key, secretAccessKey: secret_key };
        
        AWS.config.update({
          accessKeyId: keys.accessKeyId,
          secretAccessKey: keys.secretAccessKey,
          region: 'ap-south-1'
        });
        if(this.profile.demo_class && (this.profile.demo_class != this.tutor.demo_class)){
          let s3BucketName = "tution-site"; 
          var file = new Blob([this.profile.demo_class], { type: "video/*" });
          var extension = this.profile.demo_class.name.split('.')[this.profile.demo_class.name.split('.').length-1]
          var d = new Date();
          var month = d.getMonth() + 1;
          var day = d.getDate();
          var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
          var timestamp = Math.floor(Date.now() / 1000);

          // if (file.size < 20000000) {
            var s3obj = new AWS.S3({
              params: {
                Bucket: s3BucketName,
                Key:
                  'tutor/demo-video/'+ current_date+"/"+this.tutor.name + timestamp + "."+extension,
                ACL: "public-read",
              },
            });
            var upload = s3obj.upload({ Body: file });
            upload.on("httpUploadProgress", function (evt) {
                // uploadStatus == 0;
                //checkConnetionOffnOn(upload);
                var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
                console.log(per_uploaded);
              })
              .send((err, data) => {
                this.profile.demoClass = data.Location;
                this.updateTutorProfile();
              });
          // }
        }
        if(this.profile.university_certificate && (this.profile.university_certificate != this.tutor.university_certificate)){
          let s3BucketName = "tution-site"; 
          file = new Blob([this.profile.university_certificate], { type: "image/*" });
          extension = this.profile.university_certificate.name.split('.')[this.profile.university_certificate.name.split('.').length-1]
          d = new Date();
          month = d.getMonth() + 1;
          day = d.getDate();
          current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
          timestamp = Math.floor(Date.now() / 1000);

          // if (file.size < 20000000) {
            s3obj = new AWS.S3({
              params: {
                Bucket: s3BucketName,
                Key:
                  'tutor/certificate/'+ current_date+"/"+this.tutor.name + timestamp + "."+extension,
                ACL: "public-read",
              },
            });
            upload = s3obj.upload({ Body: file });
            upload.on("httpUploadProgress", function (evt) {
                // uploadStatus == 0;
                //checkConnetionOffnOn(upload);
                var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
                console.log(per_uploaded);
              })
              .send((err, data) => {
                this.profile.universityCertificate = data.Location;
                this.updateTutorProfile();
              });
          // }
        }
      }else{
        this.updateTutorProfile();
      }
    },
    addProfileImageToS3(){
      var access_key = process.env.VUE_APP_S3_ACCESS_KEY;
      var secret_key = process.env.VUE_APP_S3_SECRET_KEY;
      let keys = { accessKeyId: access_key, secretAccessKey: secret_key };
      
      AWS.config.update({
        accessKeyId: keys.accessKeyId,
        secretAccessKey: keys.secretAccessKey,
        region: 'ap-south-1'
      });

      let s3BucketName = "tution-site"; 
      var file = new Blob([this.profile_image], { type: "image/*" });
      var extension = this.profile_image.name.split('.')[this.profile_image.name.split('.').length-1]
      var d = new Date();
      var month = d.getMonth() + 1;
      var day = d.getDate();
      var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
      var timestamp = Math.floor(Date.now() / 1000);

      var s3obj = new AWS.S3({
        params: {
          Bucket: s3BucketName,
          Key:
            'tutor/profile-image/'+ current_date+"/"+this.tutor.name + timestamp + "."+extension,
          ACL: "public-read",
        },
      });
      var upload = s3obj.upload({ Body: file });
      upload.on("httpUploadProgress", function (evt) {
        var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
        console.log(per_uploaded);
        this.profile_progress = per_uploaded;
      })
      .send((err, data) => {
        this.profile.profile_pic = data.Location;
      });
    },
    updateTutorProfile(){
      axios.put(process.env.VUE_APP_TUTION_SITE_API+'/tutorprofile/'+this.tutor.tutor_id,this.profile,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.message == 'Tutor updated successfully'){
          this.loading = false;
          this.$refs['confirm-modal'].hide();
        }
      })
    }
  },
  watch:{
    $route (){
      if(this.tutor){
      this.profile.name = this.tutor?.name;
      this.profile.surname = this.tutor?.surname;
      this.profile.mobile = this.tutor?.users.mobile;
      this.profile.email = this.tutor?.users.email;
      this.profile.country = (this.tutor?.country)?(this.tutor?.country.id):null;
      this.profile.region = this.tutor?.region.id;
      this.profile.account = this.tutor?.accent_id;
      this.profile.about = this.tutor?.about;
      this.profile.qualifications = this.tutor?.qualification_id;
      this.tutor.grades.forEach(grade => {
        if(grade.is_active == 'Y'){
          this.selected_grades.push({value:grade.grade_id, text:grade.grade.name});
        }
      });
      this.tutor.subjects.forEach(sub => {
        if(sub.is_active == 'Y'){
          this.selected_subjects.push({value:sub.subject_id, text:sub.subject?.subject_name})
        }
      });
    }
    }
  }
}
</script>

<style scoped>
.card {
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    margin-bottom: 30px;
    border-radius: 2px;
}
.card-header {
    background: #fbfbfb;
    border-bottom: 1px solid #e6e5e7;
    color: #252328;
    padding: .75rem 1.25rem;
    margin-bottom: 0;
}
select.form-control {
    color: #5f5e61;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: 94% 50%;
    background-repeat: no-repeat;
    padding-right: 15px;
    border-radius: 2px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: 0 2px 1px rgb(0 0 0 / 8%);
    height: 150px;
    padding: 6px 16px;
    border: 1px solid #e8e8e8;
}
option{
  font-size:14px;
}
.select-btn a.btn {
    width: 69px;
    height: 30px;
}
.v-text-field--enclosed .v-input__prepend-inner{
  margin-top: 9px !important;
}
.v-input__prepend-outer{
  display:none !important;
}
</style>

<style>
.v-text-field--enclosed .v-input__prepend-inner{
  margin-top: 9px !important;
}
.v-input__prepend-outer{
  display:none !important;
}
</style>